import React from 'react';
import ReactDOM from 'react-dom';

import 'aos/dist/aos.css';
import 'antd/dist/antd.css';
import './assets/theme/font-awesome/css/all.css';
//import './assets/theme/css/materialize.css';
//import './assets/theme/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/theme/css/style.css';
import './assets/theme/css/style-mob.css';
import './assets/theme/custom.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
