import React, { Suspense } from "react";
import { Switch, Route, BrowserRouter, HashRouter } from "react-router-dom";

import { Spinner } from "react-bootstrap";

const DefaultLayout = React.lazy(() => import("../pages/defaultLayout"));

export default () => (
  <BrowserRouter baseName="/">
    <Suspense
      fallback={
        <div className="text-center" style={{ marginTop: "calc(30vh)" }}>
          <Spinner animation="border" />
        </div>
      }
    >
      <Switch>
        <Route path="/" component={DefaultLayout} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);
